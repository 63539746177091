@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');


/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

:root{

    /* Color styles */
    

    --table-headings-color:#A6AAAD;
--backgrounds: #F3F5F7;
--warning: #FFCDD2;
--success: #D6F5D6;
--black-and-white--black: #000000;
--black-and-white--white: #ffffff;
--blacks--black-500: #212121;
--blacks--black-400: #4D4D4D;
--blacks--black-300: #7A7A7A;
--blacks--black-200: #A6A6A6;
--blacks--black-100: #D3D3D3;
--greens--green-500: #32CD32;
--greens--green-400: #5BD75B;
--greens--green-300: #84E184;
--greens--green-200: #ADEBAD;
--greens--green-100: #D6F5D6;
--reds--red-500: #F44336;
--reds--red-400: #EF5350;
--reds--red-300: #E57373;
--reds--red-200: #EF9A9A;
--reds--red-100: #FFCDD2;
--yellows--yellow-500: #FFD60A;
--yellows--yellow-400: #FEE440;
--yellows--yellow-300: #FEEB70;
--yellows--yellow-200: #FEF2A0;
--yellows--yellow-100: #FFF8CF;
--greys--grey-500: #9E9E9E;
--greys--grey-400: #BDBDBD;
--greys--grey-300: #E0E0E0;
--greys--grey-200: #EEEEEE;
--greys--grey-100: #F5F5F5;

  /* Brand colors */
  --oranges---orange-1000: #482608;
  --oranges--orange-900: #6C390C;
  --oranges--orange-800: #8F4C10;
  --oranges--orange-700: #D77217;
  --oranges--orange-600: #EF7F1A;
  --oranges--orange-500: #F4A55F;
  --oranges--orange-400: #F9CCA3;
  --oranges--orange-300: #FBDFC6;
  --oranges--orange-200: #FDF2E8;
  --oranges--orange-100: #FEF9F4;

/* Text-size styles */
/* base size: headings--h1--bold (50.5px) */
--headings--h1: 50.5px;/* Text-size styles */
--headings--h2: 37.89px;
--headings--h3: 28.42px;
--headings--h4: 21.32px;
--headings--h5: 16px;
--body--body0: 12px;
--body--body1: 9px;

/* mat-color-pallette colors  */
--ck-color-button-on-color: var(--oranges--orange-800) !important;
--ck-color-table-column-resizer-hover: var(--oranges--orange-800) !important;
--ck-clipboard-drop-target-color: var(--oranges--orange-800) !important;
--ck-color-resizer: var(--oranges--orange-800) !important;
--ck-color-widget-type-around-button-active: var(--oranges--orange-800) !important;
--ck-color-color-grid-check-icon: var(--oranges--orange-800) !important;
--ck-color-base-active: var(--oranges--orange-800) !important;
--ck-color-base-active-focus: var(--oranges--orange-800) !important;
--ck-color-button-on-color: var(--oranges--orange-800) !important;
--bs-link-color: var(--oranges--orange-800) !important;
--bs-link-hover-color: var(--oranges--orange-700) !important;
--bs-primary: var(--oranges--orange-600) !important;

 /* Font Families  */
--heading-font:'Roboto','sans-serif';
--body-font:'Roboto','sans-serif';

/* Shadows */
    --global-box-shadow:rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    --dropdown-shadow:  0px 2px 4px rgba(0, 0, 0, 0.08);
    --card-shadows:  0px 9px 24px rgba(0, 0, 0, 0.06);
    --pricing-card-shadow:  0px 8px 16px rgba(0, 0, 0, 0.25);
    --hotel-menu-items-card:  4px 8px 8px rgba(0, 0, 0, 0.03);
    --left-sidebar-shadow:  0px 8px 33.20000076293945px rgba(0, 0, 0, 0.2);


}

*::-webkit-scrollbar{
    width: 4px;
    height:4px;
    background: #A6AAAD;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--blacks--black-300); /* Color of the scrollbar thumb */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--blacks--black-400); /* Color of the scrollbar thumb on hover */
}

@media screen and (min-width:320px){
    *{
        font-family:var(--body-font);
    }

    input{
        font-size:var(--body--body0);
        font-size: 12px;
    }

    .form-control-sm {
        min-height: 1.5rem !important;
    }

    th, .td-before{
        color:var(--table-headings-color);
    }

/* Change the outline color when the input is focused */
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    border-color: var(--oranges--orange-600) !important;
  }
  
.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: black!important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: black!important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: black!important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick{
    color: black!important;
    opacity: 0.8!important;
}

.mat-input-element {
    caret-color: black!important;
}

.mat-form-field-invalid .mat-input-element, .mat-warn .mat-input-element {
    caret-color: black!important;
}

.mat-form-field-label {
    color: rgba(0,0,0,.6)!important;
}

.mat-form-field .mat-focused .mat-form-field-label {
    color: black!important;
}


.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: black!important;
}

.mat-form-field.mat-focused .mat-form-field-ripple{
    background-color: black;
}

    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: black;
      }
}

@media screen and (min-width:320px){
    .primary-button{
        display:block;
        margin: 4px 8px;
        width:fit-content;
        border:none;
        padding:4px 8px;
        background:#EF7F1A;
        border-radius:4px;
        -webkit-border-radius:4px;
        -moz-border-radius:4px;
        -ms-border-radius:4px;
        -o-border-radius:4px;
        color:white;
        cursor:pointer;
    }

    .primary-button:hover{
        background:#8F4C10;
    }

    .secondary-button{
        display:block;
        margin: 4px 8px;
        width:fit-content;
        border:none;
        padding:4px 8px;
        background:transparent;
        border-radius:4px;
        -webkit-border-radius:4px;
        -moz-border-radius:4px;
        -ms-border-radius:4px;
        -o-border-radius:4px;
        color:#EF7F1A;
        border:1px solid #EF7F1A;
        cursor:pointer;
    }

    .secondary-button:hover{
        border:1px solid #8F4C10;
        color:#8F4C10;
    }

    .ghost-button{
        display:block;
        width:100%;
        margin:4px auto;
        padding:4px 8px;
        border:none;
        text-decoration:none;
        color:#545556;
        z-index:999;
        cursor:pointer;
    }

    .form-check-input:checked {
        background-color: var(--oranges--orange-600);
        border-color: var(--oranges--orange-600);
    }

    .fas, ::ng-deep select, ::ng-deep .ng-select, .modal-header .fas{
        cursor:pointer !important;
    }

    .form-control:focus {
        color: var(--blacks--black-400);
        background-color: #fff;
        border-color: var(--oranges--orange-500);
        outline: 0;
        box-shadow: none;
    }

    .alert-danger {
        background: var(--reds--red-300);
        color: var(--black-and-white--white);
        box-shadow: var(--global-box-shadow);
        border: none !important;
        border-radius: 4px;
    }

    .alert-success {
        background: var(--greens--green-300);
        color: var(--black-and-white--white);
        box-shadow: var(--global-box-shadow);
        border: none;
        border-radius: 4px;
    }

}

@media screen and (min-width:768px){
    .app-container, .header{
        margin-left:20% !important;
        width:calc(80% - 1px) !important;
    }
/* 
    .buttons-container{
        margin-left:calc(20% + 16px) !important;
    } */

    .app-container.full, .header.full{
        margin-left:calc(5%) !important;
        width:calc(95% - 1px) !important;
    }

    /* .main-div router-outlet {
        margin-left:20%;
        width:calc(80% - 1px);
        Custom styles for the router-outlet element
    } */

    ::ng-deep .mat-mdc-table thead {
        display: block !important;
        position: sticky;
        top: 0px;
    }
}

@media screen and (min-width:1023px)
{
    .primary-button{
        display:block;
        margin:4px auto;
        width:fit-content;
        border:none;
        padding:4px 8px;
        background:#EF7F1A;
        border-radius:4px;
        -webkit-border-radius:4px;
        -moz-border-radius:4px;
        -ms-border-radius:4px;
        -o-border-radius:4px;
        color:white;
    }

    .primary-button:hover{
        background:#8F4C10;
    }

    .secondary-button{
        display:block;
        margin:4px auto;
        width:fit-content;
        border:none;
        padding:4px 8px;
        background:transparent;
        border-radius:4px;
        -webkit-border-radius:4px;
        -moz-border-radius:4px;
        -ms-border-radius:4px;
        -o-border-radius:4px;
        color:#EF7F1A;
        border:1px solid #EF7F1A;
    }

    .secondary-button:hover{
        border:1px solid #8F4C10;
        color:#8F4C10;
    }

    .ghost-button{
        display:block;
        width:100%;
        margin:4px auto;
        padding:4px 8px;
        border:none;
        text-decoration:none;
        color:#545556;
    }

    /* .notification-warning{
        width: calc(96% - 42px);
        display: grid;
        grid-template-columns: 1% 2% calc(95% - 40px - 20px ) 2%;
        gap: 20px;
        margin: 12px auto;
        padding: 12px 24px;
        align-items: center;
        background: var(--warning);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        z-index: 9;
}

    .notification-warning #notification-warning-line{
        content: '';
        position: relative;
        height: calc(100% - 4px);
        width: 5px;
        border: 2px solid var(--reds--red-300);
        background: var(--reds--red-300);
        border-radius: 8px    ;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
}

    .notification-warning > i {
        display: block;
        font-size: 24px;
        color: var(--reds--red-300);
    }
    
    .notification-warning > #notification-warning-content > h4 {
        margin: 4px 0px;
        font-size: var(--headings--h4);
        font-weight: 600;
        letter-spacing: 1px;
        color:var(--reds--red-300);
    }

    .notification-warning > #notification-warning-content > p {
        font-size: var(--body--body0);
        font-weight: 400;
        color: var(--reds--red-300);
        display: block;
        padding: 0;
        margin: 0;
    }

    .notification-warning > button{
        display:block;
        margin:0 ;
        padding:0;
        transition:0.3s;
        -webkit-transition:0.3s;
        -moz-transition:0.3s;
        -ms-transition:0.3s;
        -o-transition:0.3s;
    }

    .notification-warning > button:hover{
        font-weight:600;
        color:var(--blacks--black-500);
        transform:scale(1.2);
        -webkit-transform:scale(1.2);
        -moz-transform:scale(1.2);
        -ms-transform:scale(1.2);
        -o-transform:scale(1.2);
    } 

    .notification-success{
        width: calc(96% - 42px);
        position: relative;
        display: grid;
        grid-template-columns: 1% 2% calc(95% - 40px - 20px ) 2%;
        gap: 20px;
        margin: 12px auto;
        padding: 12px 24px;
        align-items: center;
        background: var(--greens--green-100);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        z-index: 9;
}

    .notification-success #notification-success-line{
        content: '';
        position: relative;
        height: calc(100% - 4px);
        width: 5px;
        border: 2px solid var(--greens--green-300);
        background: var(--greens--green-300);
        border-radius: 8px    ;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
}

    .notification-success > i {
        display: block;
        font-size: 24px;
        color: var(--greens--green-300);
    }
    
    .notification-success > #notification-success-content > h4 {
        margin: 4px 0px;
        font-size: var(--headings--h4);
        font-weight: 600;
        letter-spacing: 1px;
        color:var(--greens--green-300);
    }

    .notification-success > #notification-success-content > p {
        font-size: var(--body--body0);
        font-weight: 400;
        color: var(--greens--green-300);
        display: block;
        padding: 0;
        margin: 0;
    }

    .notification-success > button{
        display:block;
        margin:0 ;
        padding:0;
        border: none;
        background: none;
        transition:0.8s;
        -webkit-transition:0.8s;
        -moz-transition:0.8s;
        -ms-transition:0.8s;
        -o-transition:0.8s;
}

    .notification-success > button:hover{
        font-weight:600;
        color:var(--blacks--black-500);
        transform:scale(1.2)rotateZ(270deg);
        -webkit-transform:scale(1.2)rotateZ(270deg);
        -moz-transform:scale(1.2)rotateZ(270deg);
        -ms-transform:scale(1.2)rotateZ(270deg);
        -o-transform:scale(1.2)rotateZ(270deg);
} 

    .notification-success > button > i{
        display:block;
        margin:0 ;
        padding:0;
        border:none;
        background:none;
        font-size: 24px;
        color: var(--greens--green-300);
    } */

}

/* Original CSS */
/* 
.fullwindow .windowform form .itemcontainer {
    height: calc(100% - 50px)!important;
}

.fullwindow .itemcontainer {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

*/

/* 
.windowform {
    padding: 0;
    height: calc(100vh - 88px)!important; */
    /*position: relative;*/
/* }

.selectize-input {
    height: 25px;
    padding-top:5px
}

.form-control {
    height: 25px;
}

.col-form-label {
    padding:4px 0 0;
    line-height:1;
}

.listheader {
    position: relative;
    height: 44px;
}

.listgroup {
    position: absolute;
    left: 0px;
    right: 0px;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 20px;
}

.listtitle {
    font-weight: 600;
    text-decoration: underline;
    margin-left: 5px;
    margin-right: 5px;
}

.context-menu {
    display: none;
    position: absolute;
    border: 1px solid black;
    border-radius: 3px;
    width: 200px;
    background: white;
    box-shadow: 10px 10px 5px #888888;
    z-index:10;
}

.context-menu ul {
   list-style: none;
   padding: 2px;
}

.context-menu ul li {
   padding: 5px 2px;
   margin-bottom: 3px;
   color: white;
   font-weight: bold;
   background-color: darkturquoise;
}

.context-menu ul li:hover {
   cursor: pointer;
   background-color: #7fffd4;
}

.Deletecontrol {
    width: 15px;
    height: 15px;
    border: 0px solid black;
    display: inline-block;
    margin-right: 5px;
}

.shadow-border{
    border:1px solid black;
}

.textmini {
    width: 10px;
}

.multiselect {
    min-height: 32px;
    padding: 4px 10px 0 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    cursor: pointer;
    margin: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

    .multiselect .selection-list > li .btn-sm {
        padding-top: 2px;
        padding-bottom: 2px;
        background: #eee;
        border: 1px solid #ccc;
        border-radius: 2px;
        font-size: .875rem;
        line-height: 1.5;
    }

.selection-list {
    margin: 0;
}

.multiselect .selection-list > li .close {
    font-size: 18px;
    margin-left: 5px;
}

.multiselect .selection-list > li {
    display: inline-block;
    margin-bottom: 4px;
    padding-right: 0;
    padding-left: 5px;
}

.file-label .close, .file-label {
    margin-left: 10px;
}

.over-flow {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.file-label {
    padding:4px 10px 2px;
    display:inline-block;
    border:1px solid #eaeaea; 
    margin:0px 10px 10px 0px;
}

.white-bg{
    background-color:#fff;
}

.rounded-corner {
    border-radius:16px;
}

.attach {
    max-width: 240px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.file-upload input{
    z-index:8;
    opacity:0;
    position:absolute;
    overflow:hidden;
    cursor:pointer;
    width:100%;
    height:100%;
    left:0;
    top:0;
    bottom:0;
}

.insertlink {
    position: absolute;
    top: 130px;
    right: 0px;
    left: 0px;
    padding: 10px 0;
    background-color: #f1f1f1;
}

.inserttable {
    position: absolute;
    top: 130px;
    right: 0px;
    left: 0px;
    padding: 10px 0;
    background-color: #f1f1f1;
}

.colorhue {
    background: linear-gradient(90deg,red,#ff0,#0f0,#0ff,#00f,#f0f,red);
    background-image: linear-gradient(90deg, red, rgb(255, 255, 0), rgb(0, 255, 0), rgb(0, 255, 255), rgb(0, 0, 255), rgb(255, 0, 255), red);
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
}

.colorpalette {
    position:absolute;
    left:-102px;
    width: 250px;
    height: 320px;
    margin: 48px auto;
    background: white;
    z-index:999;
}

.colorpalette:before {
    height: 16px;
    width: 16px;
    position: absolute;
    content: '';
    background: white;
    border: 1px solid lightgray;
    border-bottom-width: 0px;
    border-right-width: 0px;
    transform: rotate(45deg);
    top: -9px;
    left: calc(50% - 6px);
}
.active {
    background-color: #d9d9d9;
}

.btn{
    outline:none !important;
    box-shadow:none !important;
}

.bg-transparent{
    border-color:transparent;
}

.rich-text-editor {
    border: none;
    height:200px;
}

.html-editor-container {
    border-top:1px solid black;
}

.html-editor {
    padding:5px;
    border-bottom:1px solid black;
}

.modal-header {
    background-color: #f5f5f5;
}

.entry-wrap {
    height: 100%;
    overflow-y:auto;
    border: 1px solid black;
}

.sundry-wrap {
    height: 128px;
    overflow-y: auto;
    border: 1px solid black;
}

.item-wrap {
    height: 200px;
    overflow-y: auto;
    border: 1px solid black;
}

.description-wrap {
    height: 15.5em;
    width: 100%;
    overflow-y: auto;
    border: 1px solid black;
}

.final-wrap {
    height: 128px;
    width: 100%;
    border: 1px solid black;
}

fieldset.groupbox-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 0.5em 0.5em !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

legend.groupbox-border {
    font-size: 1.0em !important;
    text-align: left !important;
    width: auto;
    padding: 0px 0px 0px 0px;
    border-bottom: none;
}

.right-inner-addon {
    position: relative;
}

.right-inner-addon input {
    padding-right: 30px;
}

.right-inner-addon .setarrow {
    position: absolute;
    right: 3px;
    top: 4px;
    padding: 4px 2px 6px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 1px solid black;
    line-height: 6px;
    cursor: pointer;
}

.right-inner-addon .setpath {
    position: absolute;
    right: 24px;
    top: 4px;
    padding: 2px 2px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 1px solid black;
    line-height: 12px;
    cursor: pointer;
}

.customdropdown{
    left:0;
    right:0;
    background-color:white;
    opacity:1;
}

.customcombobox {
    cursor: pointer;
}

.customlist {
    cursor: pointer;
    width: 100%;
    height:30px;
}

.customsizelist {
    cursor: pointer;
    width: 100%;
}

.customcomboboxtable {
    cursor: pointer;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.customcomboboxtable.focus {
    cursor: pointer;
    padding: 6px;
    line-height: 11px;
    border: 2px solid black;
   -webkit-box-shadow: none;
    box-shadow: none;
   -webkit-border-radius: 3px;
   -moz-border-radius: 3px;
    border-radius: 3px;
}

table .selectize-input {
    height:25px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

table .selectize-input.focus {
    padding:6px;
    line-height: 11px;
    border:2px solid black;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

select,input .tableselect{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-top: 0px;
}

.tableselect {
    border:none !important;
    width: 100%;
    line-height: 100%;
}

.basis {
    text-decoration: none;
}

.basis:hover {
    text-decoration: none;
}

td > a {
    color: black;
    text-decoration: none;
}

td > a:hover {
    color: black;
    text-decoration: none;
}

.border bottom{
    width:100%;
}

.required{
    color:red;
}

.navbar {
    height:40px;
}

.input-group {
    height:30px;
}

#searchid {
    height:30px;
}

.navlink {
    color:black;
    text-decoration: none;
}

.navlink:hover {
    color: black;
    text-decoration: none;
}

.addnewlink {
    color: blue;
    text-decoration: none;
}

.addnewlink:hover {
    color: blue;
    text-decoration: none;
}

#searchidimg {
    vertical-align:top;
}

footer {
    position:absolute;
    bottom:0;
    width:100%;
} */


/* @media only screen and (max-width: 767px) { */
    /* .off-padding {
        padding-left:0px!important;
        margin-top:3px;
    }

    .entry-wrap {
        min-height: 200px;
    }

    .container{
        min-width:767px;
        overflow-x:scroll;
    }

    .Settings {
        min-width: 767px;
    }

    .navbar {
        min-width:767px;
    }

    .navbar-collapse {
        position:relative;
        opacity:1;
        z-index:9;
    }

    hr:not(.paymentline) {
        min-width: 767px;
    } */
/* } */

.shadow-border{
    border: 1px solid black;
}